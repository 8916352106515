export default {
  SET_CONFIG: 'SET_CONFIG',
  SET_OFFER: 'SET_OFFER',
  SET_USER: 'SET_USER',
  SET_INFO: 'SET_INFO',
  SET_DATA: 'SET_DATA',
  SET_HEADERS: 'SET_HEADERS',
  SET_SELECTED_HEADERS: 'SET_SELECTED_HEADERS',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_TOTALS: 'SET_TOTALS',
  SET_FILTERS: 'SET_FILTERS',
  SET_TICKET: 'SET_TICKET',
  SET_BARCODE: 'SET_BARCODE',
  RESET_DATA: 'RESET_DATA',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  UPADTE_PARAMS: 'UPADTE_PARAMS',
  RESET_PARAMS: 'RESET_PARAMS',
  SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL',
  SET_ALL_HEADERS: 'SET_ALL_HEADERS',
};
